<template>
	<div>
		<ul class="examineNav">
			<li v-for="(item,index) in nav" :key="index" :class="[index==2?'active':'']" @click="apiece(item)">
				<img v-if="index==2" @click="leftBtn($event)" src="../../assets/img/getInfo/left.png" alt="左箭头-心流">
				<span>{{item}}</span>
				<img v-if="index==2" @click="rightBtn($event)" src="../../assets/img/getInfo/right.png" alt="右箭头-心流">
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		props: [
			'nav'
		],
		data() {
			return {
				
			}
		},
		components: {

		},
		mounted() {


		},
		methods: {
			leftBtn: function(e) {
				this.pageUrl(e.currentTarget.parentElement.previousElementSibling.firstElementChild.innerText)
			},
			rightBtn: function(e) {
				this.pageUrl(e.currentTarget.parentElement.nextElementSibling.firstElementChild.innerText)
			},
			apiece: function(e) {
				this.pageUrl(e)
			},
			pageUrl:function(url){
				var query={
					stage:this.$route.query.stage,
					type: this.$route.query.type,
					reportId: this.$route.query.reportId,
					auditStatus: this.$route.query.auditStatus
				}
				if(url=='审核状态'){
					this.$router.push({
						path: '/examineAuditStatus',
						query: query
					})
				}else if(url=="基本信息"){
					this.$router.push({
						path: '/examineInformation',
						query: query
					})
				}else if(url=="软性指标"){
					this.$router.push({
						path: '/examineSoftInformation',
						query: query
					})
				}else if(url=="资产负债表"){
					this.$router.push({
						path: '/examineBalanceSheet',
						query: query
					})
				}else if(url=="利润表"){
					this.$router.push({
						path: '/examineProfit',
						query: query
					})
				}
			}
		}
	}
</script>

<style scoped>
	.examineNav {
		width: 84%;
		height: 88px;
		line-height: 88px;
		margin: 0 auto 10px;
		background: url(../../assets/img/getInfo/navigation.png) center center no-repeat;
		background-size: 100% 100%;
	}

	.examineNav li {
		width: 11%;
		float: left;
		text-align: center;
		position: relative;
		cursor: pointer;
	}

	.examineNav li img {
		position: absolute;
		top: 34px;
		cursor: pointer;
		vertical-align: middle;
	}

	.examineNav li img:nth-of-type(1) {
		left:11px;
	}

	.examineNav li img:nth-of-type(2) {
		right: 8px;
	}

	.examineNav li span {
		font-size: 16px;
		font-weight: bold;
		color: #3578CB;
	}

	.examineNav li.active {
		width: 20%;
		/* background: pink; */
	}

	.examineNav li.active span {
		font-size: 18px;
		color: #FEFF6A;
	}

	.examineNav li:nth-of-type(1) {
		margin-left: 18%;
	}
</style>
