<template>
	<div>
		<div class="titleBox">
			<span></span>
			<div class="title">{{companyName}}财务比率表</div>
			<span>单位：元</span>
		</div>
		<table class="table" border="0" style="border-collapse: collapse;margin-bottom: 70px;"
			:class="versions == 1 ? 'active':''">
			<thead>
				<tr class="tableT">
					<th style="width: 37%;"></th>
					<th style="width: 21%;" v-for="(item,index) in year" :key="index">{{item}}</th>
				</tr>
			</thead>
			<tbody>
				<tr class="trTwo">
					<td>资产负债率(%)</td>
					<td v-for="(item,index) in financeRatio.liabilitiesRatio" :key="index + 1">
						{{formatCurrency.formateNum(item, 2)}}
					</td>
				</tr>
				<tr class="trTwo">
					<td>流动比率</td>
					<td v-for="(item,index) in financeRatio.curAssetsRatio" :key="index + 2">
						{{formatCurrency.formateNum(item, 2)}}
					</td>
				</tr>
				<tr class="trTwo">
					<td>EBITl利息保障倍数</td>
					<td v-for="(item,index) in financeRatio.ebitMulRatio" :key="index + 3">
						{{formatCurrency.formateNum(item, 2)}}
					</td>
				</tr>
				<tr class="trTwo">
					<td>毛利率（%）</td>
					<td v-for="(item,index) in financeRatio.grossProfitRatio" :key="index + 4">
						{{formatCurrency.formateNum(item, 2)}}
					</td>
				</tr>
				<tr class="trTwo">
					<td>净利润率（%）</td>
					<td v-for="(item,index) in financeRatio.netProfitRatio" :key="index + 5">
						{{formatCurrency.formateNum(item, 2)}}
					</td>
				</tr>
				<tr class="trTwo">
					<td>主营业务收入增长率（%）</td>
					<td v-for="(item,index) in financeRatio.incomeRatio" :key="index + 6">
						{{formatCurrency.formateNum(item, 2)}}
					</td>
				</tr>
				<tr class="trTwo">
					<td>净资产收益率（%）</td>
					<td v-for="(item,index) in financeRatio.netAssetsRatio" :key="index + 7">
						{{formatCurrency.formateNum(item, 2)}}
					</td>
				</tr>
				<tr class="trTwo">
					<td>应收账款周转率</td>
					<td v-for="(item,index) in financeRatio.receivableRatio" :key="index + 8">
						{{formatCurrency.formateNum(item, 2)}}
					</td>
				</tr>
				<tr class="trTwo">
					<td>存货周转率</td>
					<td v-for="(item,index) in financeRatio.inventoryRatio" :key="index + 9">
						{{formatCurrency.formateNum(item, 2)}}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
	import formatCurrency from '../../assets/js/numberToCurrency'
	export default ({
		props: [
			'financeRatio', 'year', 'versions', 'companyName'
		],
		data() {
			return {
				dateFormate: "",
			}
		},
		mounted() {

		},
		methods: {

		}
	})
</script>
<style scoped>
	.titleBox {
		display: flex;
		justify-content: space-between;
	}

	.titleBox span {
		line-height: 100%;
		font-size: 20px;
	}

	.titleBox .title {
		text-align: center;
		font-size: 24px;
		font-weight: 400;
		color: #282828;
		margin: 130px 0 0 0;
		line-height: 100%;
	}

	table {
		width: 100%;
		text-align: center;
		border-collapse: collapse;
		margin: 28px auto 0;
	}

	table>tbody>tr>td:first-of-type {
		text-align: left;
	}

	.title {
		margin: 0 !important;
	}

	table tr td,
	table tr th {
		height: 42px;
		position: relative;
	}

	table tr td{
		text-align: right;
	}
	
	table tr td:nth-child(n+2){
		padding-right: 63px;
	}

	table tr td:nth-of-type(2),
	table tr th:nth-of-type(2),
	table tr td:nth-of-type(3),
	table tr th:nth-of-type(3) {
		border-right: 2px dashed #BBBBBB;
	}

	table>thead>tr>th {
		font-size: 18px;
		color: #fff;
		line-height: 44px;
	}

	table>thead>tr>th:nth-child(n+2) {
		background: #388CFF;
	}

	table tr.trOne td {
		font-size: 16px;
		font-weight: bold;
	}

	table tr.trTwo td,
	table tr.trThree td {
		font-size: 14px;
	}


	table tr.trOne td:first-of-type span {
		border-left: 8px solid #00A0FE;
		padding-left: 10px;
		font-size: 16px;
		font-weight: bold;
	}

	table tr.trTwo td:first-of-type {
		padding-left: 27px;
	}

	table tr.trThree td:first-of-type {
		padding-left: 54px;
	}

	table>tbody>tr>td:first-of-type {
		width: 34%;
		text-align: left;
	}

	table tr td span {
		position: absolute;
		font-size: 12px;
		margin-left: 6px;
		top: 6px;
	}
</style>
