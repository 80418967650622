<template>
	<div>
		<div class="titleBox">
			<span>附件1</span>
			<div class="title">{{companyName}}资产负债表</div>
			<span>单位：元</span>
		</div>
		<table class="table liaTableInfo" border="0" style="border-collapse: collapse;">
			<thead>
				<tr class="noInput">
					<th></th>
					<th v-for="(item,index) in year" :key="index - 1">{{item+dateFormate}}</th>
				</tr>
			</thead>
			<tbody>
				<tr class="trOne">
					<td><span>流动资产</span></td>
					<td v-for="(item,index) in liaTableInfo.totalCurrentAssets.value" :key="index + 8">
						{{formatCurrency.formateNum(item, 2)}}
						<span
							:style="{color:(liaTableInfo.totalCurrentAssets.ratio[index]!=0&&liaTableInfo.totalCurrentAssets.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{liaTableInfo.totalCurrentAssets.ratio[index]==0?'':liaTableInfo.totalCurrentAssets.ratio[index]}}</span>
					</td>
				</tr>
				<tr class="trTwo">
					<td>货币资金</td>
					<td v-for="(item,index) in liaTableInfo.monetaryCapital.value" :key="index">
						{{formatCurrency.formateNum(item, 2)}}
						<span
							:style="{color:(liaTableInfo.monetaryCapital.ratio[index]!=0&&liaTableInfo.monetaryCapital.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{liaTableInfo.monetaryCapital.ratio[index]==0?'':liaTableInfo.monetaryCapital.ratio[index]}}</span>
					</td>
				</tr>
				<tr class="trTwo">
					<td>应收账款</td>
					<td v-for="(item,index) in liaTableInfo.receivables.value" :key="index + 4">
						{{formatCurrency.formateNum(item, 2)}}
						<span
							:style="{color:(liaTableInfo.receivables.ratio[index]!=0&&liaTableInfo.receivables.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{liaTableInfo.receivables.ratio[index]==0?'':liaTableInfo.receivables.ratio[index]}}</span>
					</td>
				</tr>
				<tr class="trTwo">
					<td>存货</td>
					<td v-for="(item,index) in liaTableInfo.inventory.value" :key="index + 8">
						{{formatCurrency.formateNum(item, 2)}}
						<span
							:style="{color:(liaTableInfo.inventory.ratio[index]!=0&&liaTableInfo.inventory.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{liaTableInfo.inventory.ratio[index]==0?'':liaTableInfo.inventory.ratio[index]}}</span>
					</td>
				</tr>
				<tr class="trOne">
					<td><span>非流动资产</span></td>
					<td v-for="(item,index) in liaTableInfo.totalNonCurrentAssets.value" :key="index + 8">
						{{formatCurrency.formateNum(item, 2)}}
						<span
							:style="{color:(liaTableInfo.totalNonCurrentAssets.ratio[index]!=0&&liaTableInfo.totalNonCurrentAssets.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{liaTableInfo.totalNonCurrentAssets.ratio[index]==0?'':liaTableInfo.totalNonCurrentAssets.ratio[index]}}</span>
					</td>
				</tr>
				<tr class="trTwo">
					<td>固定资产</td>
					<td v-for="(item,index) in (liaTableInfo.fixedAssets.value ? liaTableInfo.fixedAssets.value : ['','',''])"
						:key="index + 21">
						{{formatCurrency.formateNum(item, 2)}}
						<span
							:style="{color:(liaTableInfo.fixedAssets.ratio[index]!=0&&liaTableInfo.fixedAssets.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{liaTableInfo.fixedAssets.ratio[index]==0?'':liaTableInfo.fixedAssets.ratio[index]}}</span>
					</td>
				</tr>
				<tr class="trOne">
					<td><span>资产总计</span></td>
					<td v-for="(item,index) in liaTableInfo.totalAssets.value" :key="index + 33">
						{{formatCurrency.formateNum(item, 2)}}
						<span
							:style="{color:(liaTableInfo.totalAssets.ratio[index]!=0&&liaTableInfo.totalAssets.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{liaTableInfo.totalAssets.ratio[index]==0?'':liaTableInfo.totalAssets.ratio[index]}}</span>
					</td>
				</tr>
				<tr class="trOne">
					<td><span>流动负债</span></td>
					<td v-for="(item,index) in liaTableInfo.totalNonLiabilities.value" :key="index + 33">
						{{formatCurrency.formateNum(item, 2)}}
						<span
							:style="{color:(liaTableInfo.totalNonLiabilities.ratio[index]!=0&&liaTableInfo.totalNonLiabilities.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{liaTableInfo.totalNonLiabilities.ratio[index]==0?'':liaTableInfo.totalNonLiabilities.ratio[index]}}</span>
					</td>
				</tr>
				<tr class="trTwo">
					<td>短期借款</td>
					<td v-for="(item,index) in liaTableInfo.shortLoan.value" :key="index + 2">
						{{formatCurrency.formateNum(item, 2)}}
						<span
							:style="{color:(liaTableInfo.shortLoan.ratio[index]!=0&&liaTableInfo.shortLoan.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{liaTableInfo.shortLoan.ratio[index]==0?'':liaTableInfo.shortLoan.ratio[index]}}</span>
					</td>
				</tr>
				<tr class="trTwo">
					<td>应付账款</td>
					<td v-for="(item,index) in liaTableInfo.accountsPayable.value" :key="index + 6">
						{{formatCurrency.formateNum(item, 2)}}
						<span
							:style="{color:(liaTableInfo.accountsPayable.ratio[index]!=0&&liaTableInfo.accountsPayable.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{liaTableInfo.accountsPayable.ratio[index]==0?'':liaTableInfo.accountsPayable.ratio[index]}}</span>
					</td>
				</tr>
				<tr class="trOne">
					<td><span>非流动负债</span></td>
					<td v-for="(item,index) in liaTableInfo.totalFixedLiabilities.value" :key="index + 33">
						{{formatCurrency.formateNum(item, 2)}}
						<span
							:style="{color:(liaTableInfo.totalFixedLiabilities.ratio[index]!=0&&liaTableInfo.totalFixedLiabilities.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{liaTableInfo.totalFixedLiabilities.ratio[index]==0?'':liaTableInfo.totalFixedLiabilities.ratio[index]}}</span>
					</td>
				</tr>
				<tr class="trTwo">
					<td>长期借款</td>
					<td v-for="(item,index) in liaTableInfo.longLoan.value" :key="index + 17">
						{{formatCurrency.formateNum(item, 2)}}
						<span
							:style="{color:(liaTableInfo.longLoan.ratio[index]!=0&&liaTableInfo.longLoan.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{liaTableInfo.longLoan.ratio[index]==0?'':liaTableInfo.longLoan.ratio[index]}}</span>
					</td>
				</tr>
				<tr class="trOne">
					<td><span>负债总计</span></td>
					<td v-for="(item,index) in liaTableInfo.totalLiabilities.value" :key="index + 28">
						{{formatCurrency.formateNum(item, 2)}}
						<span
							:style="{color:(liaTableInfo.totalLiabilities.ratio[index]!=0&&liaTableInfo.totalLiabilities.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{liaTableInfo.totalLiabilities.ratio[index]==0?'':liaTableInfo.totalLiabilities.ratio[index]}}</span>
					</td>
				</tr>
				<tr class="trOne">
					<td><span>归属于母公司所有者权益</span></td>
					<td v-for="(item,index) in liaTableInfo.totalParentEquity.value" :key="index + 39">
						{{formatCurrency.formateNum(item, 2)}}
						<span
							:style="{color:(liaTableInfo.totalParentEquity.ratio[index]!=0&&liaTableInfo.totalParentEquity.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{liaTableInfo.totalParentEquity.ratio[index]==0?'':liaTableInfo.totalParentEquity.ratio[index]}}</span>
					</td>
				</tr>
				<tr class="trOne">
					<td><span>少数股东权益</span></td>
					<td v-for="(item,index) in liaTableInfo.minorityEquity.value" :key="index + 40">
						{{formatCurrency.formateNum(item, 2)}}
						<span
							:style="{color:(liaTableInfo.minorityEquity.ratio[index]!=0&&liaTableInfo.minorityEquity.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{liaTableInfo.minorityEquity.ratio[index]==0?'':liaTableInfo.minorityEquity.ratio[index]}}</span>
					</td>
				</tr>
				<tr class="trOne">
					<td><span>所有者权益（或股东权益）</span></td>
					<td v-for="(item,index) in liaTableInfo.totalOwnershipInterest.value" :key="index + 41">
						{{formatCurrency.formateNum(item, 2)}}
						<span
							:style="{color:(liaTableInfo.totalOwnershipInterest.ratio[index]!=0&&liaTableInfo.totalOwnershipInterest.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{liaTableInfo.totalOwnershipInterest.ratio[index]==0?'':liaTableInfo.totalOwnershipInterest.ratio[index]}}</span>
					</td>
				</tr>
				<tr class="trOne">
					<td><span>负债和所有者权益（或股东权益）</span></td>
					<td v-for="(item,index) in liaTableInfo.totalLiabilitiesEquity.value" :key="index + 42">
						{{formatCurrency.formateNum(item, 2)}}
						<span
							:style="{color:(liaTableInfo.totalLiabilitiesEquity.ratio[index]!=0&&liaTableInfo.totalLiabilitiesEquity.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{liaTableInfo.totalLiabilitiesEquity.ratio[index]==0?'':liaTableInfo.totalLiabilitiesEquity.ratio[index]}}</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
	import formatCurrency from '../../assets/js/numberToCurrency'
	export default ({
		props: [
			'liaTableInfo', 'year', 'versions', 'companyName'
		],
		data() {
			return {
				dateFormate: "12月31日",
				ratio: ['+22.33%', '-3.98X', '0']
			}
		},
		mounted() {
			
		},
		methods: {}
	})
</script>
<style scoped>
	.titleBox {
		display: flex;
		justify-content: space-between;
	}

	.titleBox span:nth-of-type(1) {
		line-height: 100%;
		font-size: 24px;
	}

	.titleBox span:nth-of-type(2) {
		line-height: 100%;
		font-size: 20px;
	}

	.titleBox .title {
		text-align: center;
		font-size: 24px;
		font-weight: 400;
		color: #282828;
		margin: 130px 0 0 0;
		line-height: 100%;
	}

	table {
		width: 100%;
		text-align: center;
		border-collapse: collapse;
		margin: 28px auto 0;
	}

	.title {
		margin: 0 !important;
	}

	table tr td,
	table tr th {
		height: 40px;
		position: relative;
	}
	
	table tr td{
		text-align: right;
	}
	
	table tr td:nth-child(n+2){
		padding-right: 63px;
	}

	table tr td:nth-of-type(2),
	table tr th:nth-of-type(2),
	table tr td:nth-of-type(3),
	table tr th:nth-of-type(3) {
		border-right: 2px dashed #BBBBBB;
	}

	table>thead>tr>th {
		font-size: 18px;
		color: #fff;
		line-height: 44px;
	}

	table>thead>tr>th:nth-child(n+2) {
		background: #388CFF;
	}

	table tr.trOne td {
		font-size: 16px;
		font-weight: bold;
	}

	table tr.trTwo td {
		font-size: 14px;
	}


	table tr.trOne td:first-of-type span {
		border-left: 8px solid #00A0FE;
		padding-left: 10px;
		font-size: 16px;
		font-weight: bold;
	}

	table tr.trTwo td:first-of-type {
		padding-left: 27px;
	}

	table>tbody>tr>td:first-of-type {
		width: 35%;
		text-align: left;
	}

	table tr td span {
		position: absolute;
		font-size: 12px;
		margin-left: 6px;
		top: 6px;
	}
</style>
