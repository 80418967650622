<template>
	<div v-if="dupontData">
		<!--构成表-各种率-->
		<div class="return-on-assets">
			<p>单位：万元、%</p>
			<!--第一行 资产收益率-->
			<div class="box dubangNetAssetsRatio">
				<p v-text="dubangNetAssetsRatio + '%'">XX%</p>
				<p>净资产收益率(ROE)</p>
			</div>
			<div class="link-line first-link-line"></div>
			<div class="link-box first-link-box"></div>
			<!--第二行-->
			<div class="second-line">
				<div class="box totalNetProfitRatio"
					:style="totalNetProfitRatioStatus ? 'background:url('+require('../../assets/img/report/duPontAnalysis2.png')+') center center no-repeat;background-size: cover;' : ''">
					<p v-text="totalNetProfitRatio + '%'">XX%</p>
					<p>总资产净利率</p>
					<!-- <span v-show="totalNetProfitRatioStatus">↓</span> -->
				</div>
				<span>X</span>
				<div class="box rightsMul" v-if="rightsMul"
					:style="rightsMulStatus ? 'background:url('+require('../../assets/img/report/duPontAnalysis2.png')+') center center no-repeat;background-size: cover;' : ''">
					<p v-text="Number(rightsMul).toFixed(2)">XX</p>
					<p>权益乘数</p>
					<!-- <span v-show="rightsMulStatus">↓</span> -->
				</div>
			</div>
			<div class="link-line second-link-line"></div>
			<div class="link-box second-link-box"></div>
			<div class="link-line third-link-line"></div>
			<!--第三行-->
			<div class="third-line">
				<div class="box netProfitRatio"
					:style="netProfitRatioStatus ? 'background:url('+require('../../assets/img/report/duPontAnalysis2.png')+') center center no-repeat;background-size: cover;' : ''">
					<p v-text="netProfitRatio + '%'">XX%</p>
					<p>净利润率</p>
					<!-- <span v-show="netProfitRatioStatus">↓</span> -->
				</div>
				<div class="box totalAssetsRatio"
					:style="totalAssetsRatioStatus ? 'background:url('+require('../../assets/img/report/duPontAnalysis2.png')+') center center no-repeat;background-size: cover;' : ''">
					<p v-text="totalAssetsRatio + '%'">XX</p>
					<p>总资产周转率</p>
					<!-- <span v-show="totalAssetsRatioStatus">↓</span> -->
				</div>
				<div class="box liabilitiesRatio"
					:style="liabilitiesRatioStatus ? 'background:url('+require('../../assets/img/report/duPontAnalysis2.png')+') center center no-repeat;background-size: cover;' : ''">
					<p v-text="liabilitiesRatio + '%'">XX%</p>
					<p>平均资产负债率</p>
					<!-- <span v-show="liabilitiesRatioStatus">↓</span> -->
				</div>
				<span>
					1&nbsp;&nbsp; ÷&nbsp;&nbsp; (&nbsp;1&nbsp;&nbsp;&nbsp;-
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)
				</span>
			</div>
			<div class="line-box-container">
				<div>
					<div class="link-line fourth-link-line"></div>
					<div class="link-box fourth-link-box"></div>
				</div>
				<div>
					<div class="link-line fourth-link-line"></div>
					<div class="link-box fourth-link-box"></div>
				</div>
				<div>
					<div class="link-line fourth-link-line"></div>
					<div class="link-box fourth-link-box"></div>
				</div>
			</div>
			<!--第四行-->
			<div class="fourth-line">
				<div>
					<div class="box netProfit">
						<p v-text="netProfit.toFixed(2)">XX</p>
						<p>净利润</p>
					</div>
					<span>÷</span>
					<div class="box income">
						<p v-text="income.toFixed(2)">XX</p>
						<p>营业收入</p>
					</div>
				</div>
				<div>
					<div class="box income">
						<p v-text="income.toFixed(2)">XX</p>
						<p>营业收入</p>
					</div>
					<span>÷</span>
					<div class="box avgTotalAssets">
						<p v-text="avgTotalAssets.toFixed(2)">XX</p>
						<p>平均资产总计</p>
					</div>
				</div>
				<div>
					<div class="box totalLiab">
						<p v-text="totalLiab.toFixed(2)">XX</p>
						<p>平均负债合计</p>
					</div>
					<span>÷</span>
					<div class="box avgTotalAssets">
						<p v-text="avgTotalAssets.toFixed(2)">XX</p>
						<p>平均资产总计</p>
					</div>
				</div>
			</div>
		</div>
		<!--杜邦分析解释-->
		<div class="dupon-explain">
			杜邦分析的核心是净资产收益率(ROE)，它反映了所有者投入资本的获利能力和企业 筹资、投资、资产运营等活动的效率。ROE
			的高低取决于总资产利润率和权益总资产率的水平。主要因素有三个方面:销售净利率、总资产周转率和权益乘数。
		</div>
		<!--表格-->
		<table border="1" cellspacing="0" cellpadding="0">
			<tbody>
				<tr>
					<th>杜邦分析体系指标</th>
					<th v-text="year + '年'"></th>
					<th>行业平均</th>
				</tr>
				<tr>
					<td :style="netProfitRatioStatus?'color:#f00;':''">净利润率（%）</td>
					<td v-text="netProfitRatio"></td>
					<td v-text="dupontData.industryNetProfitRatio">Data</td>
				</tr>
				<tr>
					<td :style="totalAssetsRatioStatus?'color:#f00;':''">总资产周转率（%）</td>
					<td v-text="totalAssetsRatio">Data</td>
					<td v-text="dupontData.industryTotalAssetsRatio">Data</td>
				</tr>
				<tr>
					<td :style="rightsMulStatus?'color:#f00;':''">权益乘数</td>
					<td v-text="Number(rightsMul).toFixed(2)">Data</td>
					<td v-text="Number(dupontData.industryRightsMul).toFixed(2)">Data</td>
				</tr>
				<tr>
					<td :style="dubangNetAssetsRatioStatus?'color:#f00;':''">净资产收益率（%）</td>
					<td v-text="dubangNetAssetsRatio">Data</td>
					<td v-text="dupontData.industryNetAssetsRatio">Data</td>
				</tr>
			</tbody>
		</table>
		<!--净利润率、总资产周转率、权益乘数-->
		<div class="rate-container">
			<p>1. 净利润率</p>
			<span v-text="netProfitRatioDes">2017 年公司净利润率为 XX%，低于行业平均水平。</span>
			<span>净利润率可以直观的反映企业的盈利能力，提高净利润率能够提升净资产收益率，是公司最优运营模式。提高净利润率的途径有两种:提高公司议价权或降低成本费用。</span>
		</div>
		<div class="rate-container">
			<p>2. 总资产周转率</p>
			<span v-text="totalAssetsRatioDes">2017年公司总资产周转率为XX%， 高于行业平均水平。</span>
			<span>总资产周转率体现了公司对总资产的利用效率。在不能有效提高毛利率的情况下，企业可通过提高总资产周转速度实现较好的净资产收益率。</span>
		</div>
		<div class="rate-container">
			<p>3. 权益乘数</p>
			<span v-text="rightsMulDes">2017年公司权益乘数为XX，处于行业平均水平。</span>
			<span>权益乘数主要受资产负债率的影响。资产负债率越大，权益乘数越高，企业的杠杆利益越高，但同时也应考虑到相关风险因素，如：偿债风险、资金周转风险等。</span>
		</div>
	</div>
</template>
<script>
	export default ({
		props: [
			'dupontData', 'versions'
		],
		mounted() {
			this.initPage();
		},
		data() {
			return {
				// 净资产收益率
				dubangNetAssetsRatio: "",
				// 总资产净利率
				totalNetProfitRatio: "",
				// 权益乘数
				rightsMul: 0,
				// 净利润率
				netProfitRatio: "",
				// 总资产周转率
				totalAssetsRatio: "",
				// 平均资产负债率
				liabilitiesRatio: "",
				// 净利润
				netProfit: 0,
				// 营业收入
				income: 0,
				// 平均资产总计
				avgTotalAssets: 0,
				// 平均负债合计
				totalLiab: 0,
				year: "",
			}
		},
		methods: {
			initPage: function() {
				var that = this;
				that.dubangNetAssetsRatio = that.ratioFormate(that.dupontData.dubangNetAssetsRatio);
				that.totalNetProfitRatio = that.ratioFormate(that.dupontData.totalNetProfitRatio);
				that.rightsMul = that.dupontData.rightsMul;
				that.netProfitRatio = that.ratioFormate(that.dupontData.netProfitRatio);
				that.totalAssetsRatio = that.ratioFormate(that.dupontData.totalAssetsRatio);
				that.liabilitiesRatio = that.ratioFormate(that.dupontData.liabilitiesRatio);
				that.netProfit = that.dupontData.netProfit;
				that.income = that.dupontData.income;
				that.avgTotalAssets = that.dupontData.avgTotalAssets;
				that.totalLiab = that.dupontData.totalLiab;
				that.year = that.dupontData.year;
			},
			ratioFormate: function(val) {
				return (Number(val) * 100).toFixed(2);
			}
		},
		computed: {
			// 权益乘数
			rightsMulStatus: function() {
				return (this.rightsMul - this.dupontData.industryRightsMul) / Math.abs(this.dupontData
					.industryRightsMul) < -0.6
			},
			// 总资产净利率
			totalNetProfitRatioStatus: function() {
				// return this.totalNetProfitRatio - this.dupontData.industryTotalNetProfitRatio < 0
				return (this.totalNetProfitRatio - this.dupontData.industryTotalNetProfitRatio) / Math.abs(this.dupontData
					.industryTotalNetProfitRatio) < -0.6
			},
			// 净利润率
			netProfitRatioStatus: function() {
				return (this.netProfitRatio - this.dupontData.industryNetProfitRatio) / Math.abs(this.dupontData
					.industryNetProfitRatio) < -0.6
			},
			// 总资产周转率
			totalAssetsRatioStatus: function() {
				return (this.totalAssetsRatio - this.dupontData.industryTotalAssetsRatio) / Math.abs(this
					.dupontData.industryTotalAssetsRatio) < -0.6
			},
			// 平均资产负债率
			liabilitiesRatioStatus: function() {
				return (this.liabilitiesRatio - this.dupontData.industryLiabilitiesRatio) / Math.abs(this.dupontData
					.industryLiabilitiesRatio) < -0.6
			},
			// 净资产收益率
			dubangNetAssetsRatioStatus: function() {
				return (this.dubangNetAssetsRatio - this.dupontData.industryNetAssetsRatio) / Math.abs(this.dupontData
					.industryNetAssetsRatio) < -0.6
			},
			// 净利润率语句
			netProfitRatioDes: function() {
				return this.year + '年公司净利润率为' + this.netProfitRatio + '%' + this.dupontData.netProfitDesc + '。'
			},
			// 总资产周转率语句
			totalAssetsRatioDes: function() {
				return this.year + '年公司总资产周转率为' + this.totalAssetsRatio + '%' + this.dupontData.totalAssetsDesc +
					'。'
			},
			// 权益乘数语句
			rightsMulDes: function() {
				return this.year + '年公司权益乘数为' + this.rightsMul.toFixed(2) + '%' + this.dupontData.rightsMulDesc +
					'。'
			},
		}

	})
</script>
<style scoped>
	table {
		width: 1009px;
		margin: 0 auto;
	}

	.dupontAnalyze {
		width: 100%;
		height: 1754px;
	}

	.dupontAnalyze img {
		float: left;
		margin: 0 26px 0 0;
	}

	.dupontAnalyze h3 {
		font-size: 30px;
		font-weight: bold;
		color: #121212;
		line-height: 48px;
	}

	.dupontTitle {
		margin: 115px 0 0 110px;
	}

	.dupontTitle .icon {
		width: 49px;
		height: 49px;
	}

	.dupontAnalyze table {
		font-size: 16px;
	}

	.dupontAnalyze table>tbody>tr>td:nth-of-type(2) {
		padding: 0 48px;
		border-color: #666;
		text-align: center;
	}

	.dupontAnalyze table>tbody>tr>td:nth-of-type(3) {
		padding: 0 48px;
		border-color: #666;
		text-align: center;
	}

	/* 杜邦分析 */
	.return-on-assets {
		position: relative;
		width: 1008px;
		margin: 0 auto;
		margin-bottom: 40px;
	}

	.return-on-assets>p {
		position: absolute;
		right: 0;
		top: 0;
		font-size: 16px;
		font-weight: 400;
		color: rgba(40, 40, 40, 1);
		line-height: 30px;
	}

	.return-on-assets .box {
		width: 140px;
		height: 100px;
		/* background: url(../../assets/img/report/duPontAnalysis1.svg) center center no-repeat; */
		background-size: 100% 100%;
		overflow: hidden;
		color: #fff;
		position: relative;
		top: -5px;
		box-sizing: border-box;
	}


	.return-on-assets .box>p {
		width: 100%;
		height: 16px;
		margin-top: 22%;
		font-size: 14px;
		text-align: center;
	}

	.third-line .box>p,
	.fourth-line .box>p {
		font-size: 12px;
	}

	.return-on-assets .box>p:nth-of-type(2) {
		margin-top: 39%;
	}

	.box.dubangNetAssetsRatio>p:nth-of-type(2) {
		margin-top: 34%;
	}

	.third-line .box>p:nth-of-type(1),
	.fourth-line .box>p:nth-of-type(1) {
		margin-top: 24%;
	}

	.third-line .box>p:nth-of-type(2),
	.fourth-line .box>p:nth-of-type(2) {
		margin-top: 33%;
	}

	.return-on-assets .box>span {
		position: absolute;
		right: 5px;
		top: 7px;
		font-size: 30px;
		text-align: center;
		color: #fff;
	}

	.box.dubangNetAssetsRatio {
		width: 120px;
		height: 126px;
		margin-left: 450px;
		background: url(../../assets/img/report/duPontAnalysis1.png) center center no-repeat;
		background-size: 100% 100%;
	}

	.second-line {
		width: 538px;
		margin: 0 auto;
		margin-left: 276px;
		overflow: hidden;
	}

	.second-line>.box {
		width: 105px;
		height: 112px;
		float: left;
		background: url(../../assets/img/report/duPontAnalysis3.png) center center no-repeat;
	}

	.second-line>span {
		width: 20px;
		margin-left: 121px;
		margin-top: 20px;
		text-align: center;
		vertical-align: middle;
	}

	.second-line>.box:last-child {
		float: right;
		margin-right: 33px;
	}

	.third-line {
		position: relative;
		width: 810px;
		margin-left: 116px;
		overflow: hidden;
	}


	.third-line>.box,
	.fourth-line>div>.box {
		width: 89px;
		height: 95px;
		background: url(../../assets/img/report/duPontAnalysis4.png) center center no-repeat;
		float: left;
	}

	.third-line>.box:first-of-type {
		margin-right: 225px;
		margin-left: 14px;
	}

	.third-line>.box:last-of-type {
		margin-left: 233px;
	}

	.third-line>span {
		position: absolute;
		right: 38px;
		top: 54px;
		font-size: 14px;
		color: #218CF2;
		line-height: 22px;
	}

	.fourth-line {
		margin-left: 47px;
		overflow: hidden;
	}

	.fourth-line>div {
		float: left;
		margin-right: 68px;
		overflow: hidden;
	}

	.fourth-line>div:nth-of-type(2) {
		margin-right: 78px;
	}

	.fourth-line>div:nth-of-type(3) {
		margin-right: 51px;
	}

	.fourth-line>div>span {
		float: left;
		width: 24px;
		height: 30px;
		margin-left: 23px;
		margin-right: 23px;
		margin-top: 15px;
		font-size: 22px;
		text-align: center;
	}

	.link-line {
		width: 2px;
		height: 17px;
		background: #218CF2;
	}

	.first-link-line {
		margin-left: 510px;
	}

	.second-link-line {
		margin-left: 327px;
	}

	.third-link-line {
		width: 82px;
		height: 40px;
		margin-left: 731px;
		margin-top: -40px;
		background: url(../../assets/img/report/duPontAnalysisLine.svg) center center no-repeat;
		background-size: 100% 100%;
	}

	.line-box-container {
		width: 100%;
		margin-left: 90px;
		overflow: hidden;
	}

	.line-box-container>div {
		float: left;
	}

	.line-box-container>div:first-of-type {
		margin-right: 155px;
	}

	.line-box-container>div:last-of-type {
		margin-left: 163px;
	}

	.fourth-link-line {
		margin-left: 82px;
	}

	.link-box {
		width: 160px;
		box-sizing: border-box;
		border: 2px solid #218CF2;
		border-bottom: none;
	}

	.first-link-box {
		width: 397px;
		height: 22px;
		margin-left: 323px;
	}

	.second-link-box {
		width: 318px;
		height: 22px;
		margin-left: 170px;
	}

	.line-box-container>div>.link-box {
		width: 161px;
		height: 22px;
	}

	/*杜邦分析解释*/

	.dupon-explain {
		width: 1004px;
		padding: 25px 33px 25px 22px;
		background: rgba(247, 247, 247, 1);
		margin: 0 auto;
		margin-bottom: 36px;
		font-size: 20px;
		font-weight: 400;
		color: rgba(40, 40, 40, 1);
		line-height: 30px;
		text-indent: 2em;
	}

	.page-dupon table {
		border-collapse: collapse;
		border-color: #666;
		margin-bottom: 40px;
	}

	.page-dupon table>tbody>tr {
		border-color: #666;
		height: 40px;
	}

	.page-dupon table>tbody>tr>th {
		font-size: 20px;
		color: #282828;
		font-weight: bold;
		border: 1px solid #666;
	}

	.page-dupon table>tbody>tr>td {
		width: 33%;
		text-align: center;
		font-size: 20px;
		color: #282828;
		border: 1px solid #666;
	}

	.page-dupon table>tbody>tr>td:nth-of-type(2) {
		text-align: center;
	}

	.page-dupon table>tbody>tr>td:nth-of-type(3) {
		text-align: center;
	}

	.page-dupon table>tbody>tr:nth-of-type(5)>td {
		font-weight: bold;
	}

	.rate-container {
		width: 1008px;
		margin: 0 auto;
		margin-top: 25px;
	}

	.rate-container>p {
		font-size: 20px;
		font-weight: bold;
		color: rgba(40, 40, 40, 1);
		line-height: 20px;
		margin-bottom: 16px;
	}

	.rate-container>span {
		padding: 0 24px;
		font-size: 18px;
		font-weight: 400;
		color: rgba(40, 40, 40, 1);
		line-height: 34px;
		text-indent: 2em;
	}

	.dupontAnalyze table {
		width: 1008px;
		margin: 0 auto;
	}

	.dupontAnalyze table>tbody>tr {
		font-size: 16px;
	}

	.dupontAnalyze table>tbody>tr>td:first-of-type {
		padding-left: 30px;
		text-align: left;
	}

	.dupontAnalyze table>tbody>tr>td:nth-of-type(2),
	.dupontAnalyze table>tbody>tr>td:nth-of-type(3) {
		padding-left: 48px;
		text-align: center;
	}
</style>