<template>
	<div class="main">
		<div class="bodyPositioning">
			<Header />
			<div class="formWidthHome">
				<div class="mainModule">
					<div class="turingLargePicture">
						<img src="../assets/img/filledTuring/图灵.png">
					</div>
					<div class="softIndex" @click="questionInfoAdd">
						<img src="../assets/img/filledTuring/软性指标@2x.png" alt="软性指标-心流" v-if="this.questionInfoFlag">
						<img src="../assets/img/filledTuring/wancheng.png" alt="基本信息-心流" v-else>
						<p>软性指标</p>
					</div>

					<div class="information" @click="baseInfoFlagAdd">
						<img src="../assets/img/filledTuring/jibenxinxi.png" alt="基本信息-心流" v-if="this.baseInfoFlag">
						<img src="../assets/img/filledTuring/wancheng.png" alt="基本信息-心流" v-else>
						<p>基本信息</p>
					</div>

					<div class="liabilities" @click="liabilitiesInfoFlagAdd">
						<img src="../assets/img/filledTuring/zichanfuzhai.png" alt="资产负债表-心流" v-if="this.liabilitiesInfoFlag">
						<img src="../assets/img/filledTuring/wancheng.png" alt="基本信息-心流" v-else>
						<p>资产负债表</p>
					</div>


					<div class="profitStatement" @click="profitInfoFlagAdd">
						<img src="../assets/img/filledTuring/lirun.png" alt="利润表-心流" v-if="this.profitInfoFlag">
						<img src="../assets/img/filledTuring/wancheng.png" alt="基本信息-心流" v-else>
						<p>利润表</p>
					</div>
					<div class="generateReport">
						<div class="generateReportButton" v-if="this.failed" @click="resubmitBtn">
							重新提交审核
						</div>
						<div class="generateReportButton" v-else @click="generateBtn">
							一键生成报告
						</div>
					</div>


				</div>
			</div>
			<div class="filingNo">
				<p>©2018心流网 京ICP备18036155号-2 增值电信业务经营许可证：京B2-20182113 京公网安备 11010502037130号</p>
			</div>

			<dialogBar v-model="myAlert" :type="popButonType" :title='markedWords' content=""
				@confirmJump="clickConfirmJump()"></dialogBar>

			<Footer />
		</div>

	</div>
</template>

<script>
	// 头部组件引入
	import Header from '../common/header/header.vue';
	// 尾部组件引入
	import Footer from '../common/footer/footer.vue';
	import qs from 'qs'
	import axios from 'axios';
	import {
		mapState,
		mapMutations
	} from 'vuex'
	import dialogBar from '../components/filledTuring/popue.vue'

	import $ from 'jquery'

	export default {
		data() {
			return {
				purpose: '',
				baseInfoFlag: true, //基本信息图片
				questionInfoFlag: true, //软性指标图片
				liabilitiesInfoFlag: true, //资产负债表
				profitInfoFlag: true, //利润表
				failed: false,
				mystatus: '0',
				popButonType: "",
				markedWords: "",
				myAlert: false
			}
		},
		components: {
			Header,
			Footer,
			dialogBar
		},
		created() {
			if (this.$route.query.userToken) {
				localStorage.userToken = this.$route.query.userToken
			}

			if (this.$route.query.encipher) {
				localStorage.userName = this.method.base64ToUtf8(this.$route.query.encipher)
			}

		},
		mounted() {
			// wangdi   修改判断
			// if (this.$route.query.type == 12) {
			this.projectName = this.domain.turingProject;
			// } 
			this.getinfo();
		},
		watch: {
			// watch监测到路由发生跳转时刷新一次页面
			$route() {
				location.reload();
			},
		},
		methods: {
			getinfo: function() {
				this.$post(this.projectName + 'pay/getToPayTuringReportInfo', qs.stringify({
						reportId: this.$route.query.reportId,
						purpose: this.purpose,

					}))
					.then((res) => {
						// 默认进入界面   获取信息 ------------
						// 根据check  选中图片状态  展示


						if (res.data.content.check == "") {
							var check = res.data.content.check;
						} else {
							var check = JSON.parse(res.data.content.check);
						}
						// console.log(check)
						if (check == "") {
							this.baseInfoFlag = true
							this.questionInfoFlag = true
							this.liabilitiesInfoFlag = true
							this.profitInfoFlag = true
						} else {
							// console.log("111==="+check.baseInfoFlag)
							// 基本信息
							if (check.baseInfoFlag == true) {
								this.baseInfoFlag = false
							}
							// console.log("222==="+check.questionInfoFlag)
							// 软性指标
							if (check.questionInfoFlag == true) {
								this.questionInfoFlag = false
							}
							// 资产负债
							if (check.liabilitiesInfoFlag == true) {
								this.liabilitiesInfoFlag = false
							}
							// 利润表
							if (check.profitInfoFlag == true) {
								this.profitInfoFlag = false
							}
						}

						//根据状态显示对应的按钮  0审核未通过   1审核通过   2待审核  展示

						if (res.data.content.auditStatus == '0') {
							this.failed = true
						} else {
							this.failed = false
						}

						// 点击生成报告-------------
						if (this.mystatus == 1) {
							// 点击生成报告   
							if (check.baseInfoFlag == true && check.questionInfoFlag == true && check.liabilitiesInfoFlag ==
								true && check.profitInfoFlag == true) {
								this.$router.push({
									path: '/payment',
									query: {
										stage: this.$route.query.stage,
										type: this.$route.query.type,
										reportId: this.$route.query.reportId
									}
								})
								sessionStorage.removeItem("isHistoryCache")
							} else {
								if (check == '') {
									this.myAlert = true;
									this.popButonType = 'generateBtnType';
									this.markedWords = '基本信息未填写'
									return false;
								}
								if (check.baseInfoFlag == false) {
									this.myAlert = true;
									this.popButonType = 'generateBtnType';
									this.markedWords = '基本信息未填写'
									return false;
								}
								if (check.questionInfoFlag == false) {
									this.myAlert = true;
									this.popButonType = 'generateBtnType';
									this.markedWords = '软性指标未填写'
									return false;
								}
								if (check.liabilitiesInfoFlag == false) {
									this.myAlert = true;
									this.popButonType = 'generateBtnType';
									this.markedWords = '资产负债未填写'
									return false;
								}
								if (check.profitInfoFlag == false) {
									this.myAlert = true;
									this.popButonType = 'generateBtnType';
									this.markedWords = '利润表未填写'
									return false;
								}
							}

						}
						// 重新提交
						if (this.mystatus == 2) {
							if (check.baseInfoFlag == true && check.questionInfoFlag == true && check.liabilitiesInfoFlag ==
								true && check.profitInfoFlag == true) {
								// 调用再次提交接口  弹出提示
								this.myAlert = true;
								this.popButonType = 'closeData';
								this.markedWords = '确认修改完成，重新提交审核？'
							} else {
								if (check == '') {
									this.myAlert = true;
									this.popButonType = 'generateBtnType';
									this.markedWords = '基本信息未填写'
									return false;
								}
								if (check.baseInfoFlag == false) {
									this.myAlert = true;
									this.popButonType = 'generateBtnType';
									this.markedWords = '基本信息未填写'
									return false;
								}
								if (check.questionInfoFlag == false) {
									this.myAlert = true;
									this.popButonType = 'generateBtnType';
									this.markedWords = '软性指标未填写'
									return false;
								}
								if (check.liabilitiesInfoFlag == false) {
									this.myAlert = true;
									this.popButonType = 'generateBtnType';
									this.markedWords = '资产负债未填写'
									return false;
								}
								if (check.profitInfoFlag == false) {
									this.myAlert = true;
									this.popButonType = 'generateBtnType';
									this.markedWords = '利润表未填写'
									return false;
								}
							}
						}

						// 存贮是否为测试号
						localStorage.setItem("isTestAccountKey", res.data.content.isTestAccount);
						// console.log("1111="+localStorage.getItem("isTestAccountKey"))
					});
			},
			//重新提交弹出提示  确定
			clickConfirmJump: function() {
				this.$post(this.projectName + 'auditInfo/againAudit', qs.stringify({
						reportId: this.$route.query.traceReportId ? this.$route.query.traceReportId : this
							.$route.query.reportId
					}))
					.then((res) => {
						this.$router.push({
							path: '/valuationList',
						})
					});
			},
			//重新调用提交
			resubmitBtn: function() {
				// 在验证信息填写是否完成
				this.purpose = '1'
				// 记状态  getinfo方法根据状态判断  报告或重新提交
				this.mystatus = '2'
				this.getinfo()
			},
			//一键生成报告
			generateBtn: function() {
				// 在验证信息填写是否完成
				this.purpose = '1'
				// 记状态  getinfo方法根据状态判断  报告或重新提交
				this.mystatus = '1'
				this.getinfo()
			},
			// 跳转对应的填写界面
			questionInfoAdd: function() { //软性指标
				this.$router.push({
					path: '/softIndex',
					query: {
						stage: this.$route.query.stage ? this.$route.query.stage : '2',
						type: this.$route.query.type ? this.$route.query.type : '12',
						reportId: this.$route.query.reportId
					}
				})
			},
			baseInfoFlagAdd: function() { //基本信息
				this.$router.push({
					path: '/information',
					query: {
						stage: this.$route.query.stage ? this.$route.query.stage : '2',
						type: this.$route.query.type ? this.$route.query.type : '12',
						reportId: this.$route.query.reportId
					}
				})
			},
			liabilitiesInfoFlagAdd: function() { //资产负债表
				this.$router.push({
					path: '/balanceSheet',
					query: {
						stage: this.$route.query.stage ? this.$route.query.stage : '2',
						type: this.$route.query.type ? this.$route.query.type : '12',
						reportId: this.$route.query.reportId
					}
				})
			},
			profitInfoFlagAdd: function() { //利润表
				this.$router.push({
					path: '/profitStatement',
					query: {
						stage: this.$route.query.stage ? this.$route.query.stage : '2',
						type: this.$route.query.type ? this.$route.query.type : '12',
						reportId: this.$route.query.reportId
					}
				})
			},
		}
	}
</script>

<style scoped lang="less">
	/* 表单  主体宽度 wangdi*/
	.main {
		background: url(../assets/img/filledTuring/bg.png);
		background-size: 100% 100%;
		width: 100%;
		height: 100%;
		overflow: hidden;
		// position: fixed; 
	}

	.bodyPositioning {
		width: 100%;
		height: 100%;
		position: relative;
	}

	.formWidthHome {
		width: 80%;
		height: 70%;
		// min-width: 1000px;
		margin: auto;
		margin-top: 3%;
	}

	.mainModule {
		width: 100%;
		height: 100%;
		position: relative;

	}

	.turingLargePicture {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

	}

	.turingLargePicture img {
		height: 100%;
		display: block;
	}

	// 软性指标
	.softIndex {
		width: 12%;
		position: absolute;
		top: 0;
		left: 22%;
		cursor: pointer;
		transition: all .4s; //给图片添加过渡效果 对宽高有一个0.4s的过渡
	}

	.softIndex:hover {
		transform: scale(1.1); //当鼠标经过时div里面的图片宽高同时变为原来的1.1倍
		cursor: pointer; //并且光标变为指示链接的指针（一只手）
	}

	.softIndex:hover p {
		color: #fff;
		background: url("../assets/img/filledTuring/科技框.png");
		background-size: 100% 100%;
	}

	.softIndex img {
		width: 100%;
	}

	.softIndex p {
		width: 80%;
		height: 28px;
		font-size: 14px;
		text-align: center;
		color: #01D9F7;
		background: url("../assets/img/filledTuring/kejikuang.png");
		background-size: 100% 100%;
		line-height: 28px;
		margin: 10px auto 0 auto;
	}

	// 基本信息
	.information {
		// height:22%;
		width: 12%;
		position: absolute;
		bottom: 20%;
		left: 9%;
		cursor: pointer;
		transition: all .4s; //给图片添加过渡效果 对宽高有一个0.4s的过渡
	}

	.information:hover {
		transform: scale(1.1); //当鼠标经过时div里面的图片宽高同时变为原来的1.1倍
		cursor: pointer; //并且光标变为指示链接的指针（一只手）
	}

	.information:hover p {
		color: #fff;
		background: url("../assets/img/filledTuring/科技框.png");
		background-size: 100% 100%;
	}

	.information img {
		width: 100%;
	}

	.information p {
		width: 80%;
		height: 28px;
		font-size: 14px;
		text-align: center;
		color: #01D9F7;
		background: url("../assets/img/filledTuring/kejikuang.png");
		background-size: 100% 100%;
		line-height: 28px;
		margin: 10px auto 0 auto;
	}

	// 资产负债表
	.liabilities {
		width: 12%;
		position: absolute;
		top: 1.5%;
		right: 22%;
		cursor: pointer;
		transition: all .4s; //给图片添加过渡效果 对宽高有一个0.4s的过渡
	}

	.liabilities:hover {
		transform: scale(1.1); //当鼠标经过时div里面的图片宽高同时变为原来的1.1倍
		cursor: pointer; //并且光标变为指示链接的指针（一只手）
	}

	.liabilities:hover p {
		color: #fff;

		background: url("../assets/img/filledTuring/科技框.png");
		background-size: 100% 100%;
	}

	.liabilities img {
		width: 100%;
	}

	.liabilities p {
		width: 80%;
		height: 28px;
		font-size: 14px;
		text-align: center;
		color: #01D9F7;
		background: url("../assets/img/filledTuring/kejikuang.png");
		background-size: 100% 100%;
		line-height: 28px;
		margin: 10px auto 0 auto;
	}

	// 利润表
	.profitStatement {
		width: 12%;
		position: absolute;
		bottom: 20%;
		right: 9%;
		cursor: pointer;
		transition: all .4s; //给图片添加过渡效果 对宽高有一个0.4s的过渡
	}

	.profitStatement:hover {
		transform: scale(1.1); //当鼠标经过时div里面的图片宽高同时变为原来的1.1倍
		cursor: pointer; //并且光标变为指示链接的指针（一只手）
	}

	.profitStatement:hover p {
		color: #fff;
		background: url("../assets/img/filledTuring/科技框.png");
		background-size: 100% 100%;
	}

	.profitStatement img {
		width: 100%;
	}

	.profitStatement p {
		width: 80%;
		height: 28px;
		font-size: 14px;
		text-align: center;
		color: #01D9F7;
		background: url("../assets/img/filledTuring/kejikuang.png");
		background-size: 100% 100%;
		line-height: 28px;
		margin: 10px auto 0 auto;
	}

	.generateReport {
		width: 100%;
		// height: 100px;
		position: absolute;
		left: 0;
		bottom: 4%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.generateReportButton {
		display: inline-block;
		font-size: 25px;
		color: #01D9F7;
		font-weight: bold;
		background: url("../assets/img/filledTuring/scbg.png");
		background-size: 100% 100%;
		text-align: center;
		padding: 10px 30px;
		cursor: pointer;
		line-height: 35px;
		transition: all .4s; //给图片添加过渡效果 对宽高有一个0.4s的过渡
	}

	.generateReportButton:hover {
		transform: scale(1.1); //当鼠标经过时div里面的图片宽高同时变为原来的1.1倍
		cursor: pointer; //并且光标变为指示链接的指针（一只手）
		// color: #fff;
	}

	.filingNo {
		width: 100%;
		color: #fff;
		// text-align: center;
		position: absolute;
		bottom: 15px;
		text-align: center;
	}

	@media screen and (min-width:1500px) {
		.softIndex p {
			width: 70%;
			height: 28px;
			font-size: 14px;
			text-align: center;
			color: #01D9F7;
			background: url("../assets/img/filledTuring/kejikuang.png");
			background-size: 100% 100%;
			line-height: 28px;
			margin: 10px auto 0 auto;
		}

		.information p {
			width: 70%;
			height: 28px;
			font-size: 14px;
			text-align: center;
			color: #01D9F7;
			background: url("../assets/img/filledTuring/kejikuang.png");
			background-size: 100% 100%;
			line-height: 28px;
			margin: 10px auto 0 auto;
		}

		.liabilities p {
			width: 70%;
			height: 28px;
			font-size: 14px;
			text-align: center;
			color: #01D9F7;
			background: url("../assets/img/filledTuring/kejikuang.png");
			background-size: 100% 100%;
			line-height: 28px;
			margin: 10px auto 0 auto;
		}

		.profitStatement p {
			width: 70%;
			height: 28px;
			font-size: 14px;
			text-align: center;
			color: #01D9F7;
			background: url("../assets/img/filledTuring/kejikuang.png");
			background-size: 100% 100%;
			line-height: 28px;
			margin: 10px auto 0 auto;
		}
	}
</style>