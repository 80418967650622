<template>
    <div>
        <div class="titleBox">
            <span>附件2</span>
            <div class="title">{{companyName}}利润表</div>
            <span>单位：元</span>
        </div>
        <table class="table profitTableInfo" border="0" style="border-collapse: collapse;margin-bottom: 70px;" :class="versions == 1 ? 'active':''">
            <thead>
                <tr class="tableT">
                    <th style="width: 37%;"></th>
                    <th style="width: 21%;" v-for="(item,index) in year" :key="index">{{item + dateFormate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr class="trOne">
                    <td><span>营业收入</span></td>
                    <td v-for="(item,index) in profitTableInfo.income.value" :key="index + 1">
					{{formatCurrency.formateNum(item, 2)}}
					<span :style="{color:(profitTableInfo.income.ratio[index]!=0&&profitTableInfo.income.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{profitTableInfo.income.ratio[index]==0?'':profitTableInfo.income.ratio[index]}}</span>
					</td>
                </tr>
                <tr class="trTwo">
                    <td style="padding-left: 10px;">减:营业成本</td>
                    <td v-for="(item,index) in profitTableInfo.cost.value" :key="index + 2">
					{{formatCurrency.formateNum(item, 2)}}
					<span :style="{color:(profitTableInfo.cost.ratio[index]!=0&&profitTableInfo.cost.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{profitTableInfo.cost.ratio[index]==0?'':profitTableInfo.cost.ratio[index]}}</span>
					</td>
                </tr>
                <tr class="trTwo">
                    <td>税金及附加</td>
                    <td v-for="(item,index) in profitTableInfo.taxAdditional.value" :key="index + 1">
					{{formatCurrency.formateNum(item, 2)}}
					<span :style="{color:(profitTableInfo.taxAdditional.ratio[index]!=0&&profitTableInfo.taxAdditional.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{profitTableInfo.taxAdditional.ratio[index]==0?'':profitTableInfo.taxAdditional.ratio[index]}}</span>
					</td>
                </tr>
                <tr class="trTwo">
                    <td>销售费用</td>
                    <td v-for="(item,index) in profitTableInfo.saleCost.value" :key="index + 1">
					{{formatCurrency.formateNum(item, 2)}}
					<span :style="{color:(profitTableInfo.saleCost.ratio[index]!=0&&profitTableInfo.saleCost.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{profitTableInfo.saleCost.ratio[index]==0?'':profitTableInfo.saleCost.ratio[index]}}</span>
					</td>
                </tr>
                <tr class="trTwo">
                    <td>管理费用</td>
                    <td v-for="(item,index) in profitTableInfo.manageCost.value" :key="index + 1">
					{{formatCurrency.formateNum(item, 2)}}
					<span :style="{color:(profitTableInfo.manageCost.ratio[index]!=0&&profitTableInfo.manageCost.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{profitTableInfo.manageCost.ratio[index]==0?'':profitTableInfo.manageCost.ratio[index]}}</span>
					</td>
                </tr>
                <tr class="trTwo">
                    <td>研发费用</td>
                    <td v-for="(item,index) in (profitTableInfo.researchCost.value ? profitTableInfo.researchCost.value : ['','',''])" :key="index + 1">
					{{formatCurrency.formateNum(item, 2)}}
					<span :style="{color:(profitTableInfo.researchCost.ratio[index]!=0&&profitTableInfo.researchCost.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{profitTableInfo.researchCost.ratio[index]==0?'':profitTableInfo.researchCost.ratio[index]}}</span>
					</td>
                </tr>
                <tr class="trTwo">
                    <td>财务费用</td>
                    <td v-for="(item,index) in profitTableInfo.financeCost.value" :key="index + 1">
					{{formatCurrency.formateNum(item, 2)}}
					<span :style="{color:(profitTableInfo.financeCost.ratio[index]!=0&&profitTableInfo.financeCost.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{profitTableInfo.financeCost.ratio[index]==0?'':profitTableInfo.financeCost.ratio[index]}}</span>
					</td>
                </tr>
                <tr class="trThree">
                    <td>其中:利息支出</td>
                    <td v-for="(item,index) in profitTableInfo.interestCost.value" :key="index + 1">
					{{formatCurrency.formateNum(item, 2)}}
					<span :style="{color:(profitTableInfo.interestCost.ratio[index]!=0&&profitTableInfo.interestCost.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{profitTableInfo.interestCost.ratio[index]==0?'':profitTableInfo.interestCost.ratio[index]}}</span>
					</td>
                </tr>
                
                <tr class="trOne">
                    <td><span>营业利润</span></td>
                    <td v-for="(item,index) in profitTableInfo.businessProfit.value" :key="index + 1">
					{{formatCurrency.formateNum(item, 2)}}
					<span :style="{color:(profitTableInfo.businessProfit.ratio[index]!=0&&profitTableInfo.businessProfit.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{profitTableInfo.businessProfit.ratio[index]==0?'':profitTableInfo.businessProfit.ratio[index]}}</span>
					</td>
                </tr>
                <tr class="trOne">
                    <td><span>利润总额</span></td>
                    <td v-for="(item,index) in profitTableInfo.totalProfit.value" :key="index + 1">
					{{formatCurrency.formateNum(item, 2)}}
					<span :style="{color:(profitTableInfo.totalProfit.ratio[index]!=0&&profitTableInfo.totalProfit.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{profitTableInfo.totalProfit.ratio[index]==0?'':profitTableInfo.totalProfit.ratio[index]}}</span>
					</td>
                </tr>
                <tr class="trTwo">
                    <td><span>减:所得税费用</span></td>
                    <td v-for="(item,index) in profitTableInfo.incomeTax.value" :key="index + 1">
					{{formatCurrency.formateNum(item, 2)}}
					<span :style="{color:(profitTableInfo.incomeTax.ratio[index]!=0&&profitTableInfo.incomeTax.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{profitTableInfo.incomeTax.ratio[index]==0?'':profitTableInfo.incomeTax.ratio[index]}}</span>
					</td>
                </tr>
                <tr class="trOne">
                    <td><span>净利润</span></td>
                    <td v-for="(item,index) in profitTableInfo.netProfit.value" :key="index + 1">
					{{formatCurrency.formateNum(item, 2)}}
					<span :style="{color:(profitTableInfo.netProfit.ratio[index]!=0&&profitTableInfo.netProfit.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{profitTableInfo.netProfit.ratio[index]==0?'':profitTableInfo.netProfit.ratio[index]}}</span>
					</td>
                </tr>
				<tr class="trOne">
				    <td><span>折旧与摊销</span></td>
				    <td v-for="(item,index) in profitTableInfo.editda.value" :key="index + 1">
					{{formatCurrency.formateNum(item, 2)}}
					<span :style="{color:(profitTableInfo.editda.ratio[index]!=0&&profitTableInfo.editda.ratio[index].substring(0,1)=='+'?'#388CFF':'#BCBCBC')}">{{profitTableInfo.editda.ratio[index]==0?'':profitTableInfo.editda.ratio[index]}}</span>
					</td>
				</tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import formatCurrency from '../../assets/js/numberToCurrency'
export default ({
    props: [
        'profitTableInfo', 'year', 'versions','companyName'
    ],
    data() {
        return {
            dateFormate: "度",
        }
    },
    mounted(){
        
    },
    methods: {

    }
})
</script>
<style scoped>
    .titleBox {
        display: flex;
        justify-content: space-between;
		margin-top: 91px;
    }
    .titleBox span {
        line-height: 100%;
        font-size: 20px;
    }
    
    .titleBox .title {
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        color: #282828;
        margin: 130px 0 0 0;
        line-height: 100%;
    }
    table {
        width: 100%;
        text-align: center;
        border-collapse: collapse;
		margin: 28px auto 0;
    }
    table>tbody>tr>td:first-of-type {
        text-align: left;
    }
	
    .title {
        margin: 0 !important;
    }
    table tr td,
    table tr th {
    	height: 42px;
    	position: relative;
    }
	
	table tr td{
		text-align: right;
	}
	
	table tr td:nth-child(n+2){
		padding-right: 63px;
	}
    
    table tr td:nth-of-type(2),
    table tr th:nth-of-type(2),
    table tr td:nth-of-type(3),
    table tr th:nth-of-type(3) {
    	border-right: 2px dashed #BBBBBB;
    }
    
    table>thead>tr>th {
    	font-size: 18px;
    	color: #fff;
    	line-height: 44px;
    }
    
    table>thead>tr>th:nth-child(n+2) {
    	background: #388CFF;
    }
    
    table tr.trOne td {
    	font-size: 16px;
    	font-weight: bold;
    }
    
    table tr.trTwo td,
	 table tr.trThree td{
    	font-size: 14px;
    }
    
    
    table tr.trOne td:first-of-type span {
    	border-left: 8px solid #00A0FE;
    	padding-left: 10px;
    	font-size: 16px;
    	font-weight: bold;
    }
    
    table tr.trTwo td:first-of-type {
    	padding-left: 27px;
    }
	
	table tr.trThree td:first-of-type {
		padding-left: 54px;
	}
    
    table>tbody>tr>td:first-of-type {
    	width: 34%;
    	text-align: left;
    }
    
    table tr td span {
    	position: absolute;
    	font-size: 12px;
    	margin-left: 6px;
    	top: 6px;
    }
</style>
