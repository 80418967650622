import Vue from 'vue'
import Router from 'vue-router'
// --------wangdi
import index from '@/components/index.vue' //首页
import home from '@/components/home.vue' //首页
import header from '@/common/header/header.vue' //头部
import footer from '@/common/footer/footer.vue' //尾部
import information from '@/components/filledTuring/information.vue' //基本信息
import softIndex from '@/components/filledTuring/softIndex.vue' //软性指标
import balanceSheet from '@/components/filledTuring/balanceSheet.vue' //资产负债表
import profitStatement from '@/components/filledTuring/profitStatement.vue' //利润表
import valuationList from '@/components/filledTuring/valuationList.vue' //估值报告列表
import payment from '@/components/filledTuring/payment.vue'
import login from '@/components/logo/login.vue'
import register from '@/components/logo/register.vue' // 注册
import registration from '@/components/logo/registration.vue' // 注册协议
import disclaimer from '@/components/logo/disclaimer.vue' // 免责声明
import confidentiality from '@/components/logo/confidentiality.vue' // 隐私政策
import forgetPassword from '@/components/logo/forgetPassword.vue' // 忘记密码
// --------wangdi


import turingReport from '@/components/reportPage/turingReport.vue'
import examineInformation from '@/components/getInfo/examineInformation.vue' //查看信息中的基本信息
import examineSoftInformation from '@/components/getInfo/examineSoftInformation.vue' //查看信息中的软性指标
import examineBalanceSheet from '@/components/getInfo/examineBalanceSheet.vue' //查看信息中的资产负债表
import examineProfit from '@/components/getInfo/examineProfit.vue' //查看信息中的利润表
import examineAuditStatus from '@/components/getInfo/examineAuditStatus.vue' //查看信息中的审核状态


// 报错界面
import notFound from '@/components/status/notFound.vue'
import serverError from '@/components/status/serverError.vue'


// 点击相同的路由报错（解决 NavigationDuplicated: Avoided redundant navigation to current location 报错）
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}


Vue.use(Router)


export default new Router({
	// mode: 'history', //打包时要注释掉避免路由失效  (要去掉路径上的#号时需设置为history这个模式，上线时不需要注释，可以去掉路径上的#，还需要配置nginx )
	// base: process.env.BASE_URL,
	routes: [{
			path: '/',
			name: 'index',
			component: index,
			meta: {
				title: '图灵估值'
			}
		}, {
			path: '/home',
			name: 'home',
			component: home,
			meta: {
				title: '图灵估值',
				content: {
					keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
					description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
				}
			}
		}, {
			path: '/header',
			name: 'header',
			component: header,
			meta: {
				title: '图灵估值',
				content: {
					keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
					description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
				}
			}
		}, {
			path: '/footer',
			name: 'footer',
			component: footer,
			meta: {
				title: '图灵估值',
				content: {
					keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
					description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
				}
			}
		}, {
			path: '/information',
			name: 'information',
			component: information,
			meta: {
				title: '基本信息',
				content: {
					keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
					description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
				}
			}
		}, {
			path: '/softIndex',
			name: 'softIndex',
			component: softIndex,
			meta: {
				title: '软性指标',
				content: {
					keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
					description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
				}
			}
		}, {
			path: '/balanceSheet',
			name: 'balanceSheet',
			component: balanceSheet,
			meta: {
				title: '资产负债表',
				content: {
					keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
					description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
				}
			}
		}, {
			path: '/profitStatement',
			name: 'profitStatement',
			component: profitStatement,
			meta: {
				title: '利润表',
				content: {
					keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
					description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
				}
			}
		}, {
			path: '/valuationList',
			name: 'valuationList',
			component: valuationList,
			meta: {
				title: '估值报告',
				content: {
					keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
					description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
				}
			}
		}, {
			path: '/payment',
			name: 'payment',
			component: payment,
			meta: {
				title: '立即支付'
			}
		},
		{
			path: '/login',
			name: 'login',
			component: login,
			meta: {
				title: '登录'
			}
		}, {
			path: '/register',
			name: 'register',
			component: register,
			meta: {
				title: "注册"
			}
		}, {
			path: '/registration',
			name: 'registration',
			component: registration,
			meta: {
				title: "心流网注册协议"
			}
		}, {
			path: '/disclaimer',
			name: 'disclaimer',
			component: disclaimer,
			meta: {
				title: "免责声明"
			}
		}, {
			path: '/confidentiality',
			name: 'confidentiality',
			component: confidentiality,
			meta: {
				title: "隐私政策"
			}
		}, {
			path: '/forgetPassword',
			name: 'forgetPassword',
			component: forgetPassword,
			meta: {
				title: "忘记密码"
			}
		}, {
			path: '/turingReport',
			name: 'turingReport',
			component: turingReport,
			meta: {
				title: "图灵报告"
			}
		},
		{
			path: '/examineInformation',
			name: 'examineInformation',
			component: examineInformation,
			meta: {
				title: '线上估值_公司股权估值,北京在线估值公司-心流慧估',
				content: {
					keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
					description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
				}
			}
		},
		{
			path: '/examineSoftInformation',
			name: 'examineSoftInformation',
			component: examineSoftInformation,
			meta: {
				title: '线上估值_公司股权估值,北京在线估值公司-心流慧估',
				content: {
					keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
					description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
				}
			}
		},
		{
			path: '/examineBalanceSheet',
			name: 'examineBalanceSheet',
			component: examineBalanceSheet,
			meta: {
				title: '线上估值_公司股权估值,北京在线估值公司-心流慧估',
				content: {
					keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
					description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
				}
			}
		},
		{
			path: '/examineProfit',
			name: 'examineProfit',
			component: examineProfit,
			meta: {
				title: '线上估值_公司股权估值,北京在线估值公司-心流慧估',
				content: {
					keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
					description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
				}
			}
		},
		{
			path: '/examineAuditStatus',
			name: 'examineAuditStatus',
			component: examineAuditStatus,
			meta: {
				title: '线上估值_公司股权估值,北京在线估值公司-心流慧估',
				content: {
					keywords: '心流网,心流慧估,心流,估值,线上估值,公司股权估值,数据估值,企业体检,在线尽调',
					description: '心流曼殊估值专家系统为初创期企业、成长期企业、成熟期企业提供线上股权评估、企业价值诊断。估值方法专业、报价合理，在线估值公司就找心流慧估。'
				}
			}
		}, {
			path: '/notFound',
			name: 'notFound',
			component: notFound,
			meta: {
				title: '404错误'
			}
		}, {
			path: '/serverError',
			name: 'serverError',
			component: serverError,
			meta: {
				title: '500错误'
			}
		},
	]
})